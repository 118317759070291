import React from "react";
import { getCode, TS } from "../../util";
import { Desc, FieldProp, FieldProps } from "./FieldProps";

export class BEFieldProps extends FieldProps {
  constructor(props) {
    super(props); // props is for initial setup

    this.fieldProps = [
      new FieldProp("gwp", "gwp-img", (e) =>
        Desc(
          "Dit perceel ligt in een grondwaterbeschermingsgebied",
          e,
          "https://www.dov.vlaanderen.be/page/waterwingebieden-en-beschermingszones",
          "Waterwingebieden en beschermingszones"
        )
      ),
      new FieldProp("permanent_grass", "grs-img", "Dit is blijvend grasland volgens de overheid"),
      new FieldProp("natura_2000", "n2k-img", (e) =>
        Desc(
          "Dit perceel valt in een gebied onder Natura 2000 regeling",
          e,
          "https://ec.europa.eu/environment/nature/natura2000/index_en.htm",
          "Europese Commissie"
        )
      ),
      new FieldProp("erosion", "erosion-img", (e) =>
        Desc(
          "Erosie-gevoeligheid",
          e,
          "https://www.dov.vlaanderen.be/page/bodemerosie",
          "Databank Ondergrond Vlaanderen"
        )
      ),
      new FieldProp("nitrate", "nitrate-img", (e) =>
        Desc(
          "Nitraatgevoelige of nitraatrisico zone",
          e,
          "https://www.dov.vlaanderen.be/page/nitraatgevoelige-zones",
          "Databank Ondergrond Vlaanderen"
        )
      ),
      new FieldProp(
        "nitrate_area_2025",
        "nitrate-img nitrate-img-2025",
        (e) =>
          Desc(
            "Gebiedstype nitraat mestdecreet 2025-2026",
            e,
            "https://www.vlm.be/nl/themas/Mestbank/gebiedsgerichte_aanpak/gebiedstypes/Paginas/default.aspx",
            "gebiedstype nitraat mestdecreet bij de Vlaamse Land Maatschappij"
          ),
        (e) => getCode(e, "name").slice(-1)
      ),
      new FieldProp(
        "nitrate_area",
        "nitrate-img nitrate-img-2023",
        (e) =>
          Desc(
            "Gebiedstype nitraat mestdecreet 2023-2024",
            e,
            "https://www.vlm.be/nl/themas/Mestbank/gebiedsgerichte_aanpak/gebiedstypes/Paginas/default.aspx",
            "gebiedstype nitraat mestdecreet bij de Vlaamse Land Maatschappij"
          ),
        (e) => getCode(e, "name").slice(-1)
      ),
      new FieldProp(
        "nitrate_area_2023",
        "nitrate-img nitrate-img-2023",
        (e) =>
          Desc(
            "Gebiedstype nitraat mestdecreet 2023-2024",
            e,
            "https://www.vlm.be/nl/themas/Mestbank/gebiedsgerichte_aanpak/gebiedstypes/Paginas/default.aspx",
            "gebiedstype nitraat mestdecreet bij de Vlaamse Land Maatschappij"
          ),
        (e) => getCode(e, "name").slice(-1)
      ),
      new FieldProp(
        "nitrate_area_2021",
        "nitrate-img nitrate-img-2021",
        (e) =>
          Desc(
            "Gebiedstype nitraat mestdecreet 2021-2022",
            e,
            "https://www.vlm.be/nl/themas/Mestbank/gebiedsgerichte_aanpak/gebiedstypes/Paginas/default.aspx",
            "gebiedstype nitraat mestdecreet bij de Vlaamse Land Maatschappij"
          ),
        (e) => getCode(e, "name").slice(-1)
      ),
      new FieldProp("organic", "organic-img", "Biologisch perceel"),
      new FieldProp("property_i", "state-img", (e) => (
        <TS
          t="This plot is owned by state institute <i>{{name}}</i>"
          o={{ name: getCode(e, "name") }}
        />
      )),
    ];
  }
}
